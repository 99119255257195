<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <div v-if="active && hasInvoiceData">

            <form-wizard color="#7367F0" :title="`FACTURA ${invoiceData.folio} EN PROCESO PRE-RUTA`" 
                subtitle="Validación de datos, proceda con precaución" shape="square" finish-button-text="Enrutar"
                back-button-text="Anterior" next-button-text="Siguiente" class="mb-3" @on-complete="formSubmitted"
                :hide-buttons="false"
                :button-class="'btn-sm small'">

                <template #title>
                    <h4>FACTURA {{invoiceData.folio}} EN PROCESO PRE-RUTA</h4>
                    <small>Validación de datos, proceda con precaución</small>

                    <b-button @click="cancelPrerouting" size="sm" variant="outline-danger" class="float-right">
                        <feather-icon icon="XIcon" />
                        Cancelar
                    </b-button>
                </template>

                <!-- address details tab -->
                <tab-content title="Domicilio" :before-change="validationForm">
                    <validation-observer ref="accountRules" tag="form">
                        <b-row>
                            <b-col cols="12" class="mb-2">
                                <h5 class="mb-0">
                                    Dirección de Entrega
                                </h5>
                                <small class="text-muted">
                                    Validar dirección, teléfono, contacto y/o referencias
                                </small>
                            </b-col>

                            <b-col md="6">
                                <b-form-group label="Dirección" label-for="address">
                                    <v-select v-model="invoiceData.dir_Cli_Id" class="select-size-sm" :options="invoiceData.cliente.direcciones.map(item => ({...item, label: item.nombre_Consig + ' ' + item.calle}))"
                                        label="label"
                                        :reduce="option => option.dir_Cli_Id"
                                        :clearable="false"
                                        :searchable="false"
                                        @input="selectAddress"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="6">
                                <b-form-group label="Referencia" label-for="referencia">
                                    <b-form-input readonly id="referencia" v-model="invoiceData.direccion_Referencia"
                                            placeholder="Referencia" size="sm" />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Teléfono" label-for="telefono">
                                    <b-form-input readonly id="telefono" v-model="invoiceData.telefono1"
                                            placeholder="Tel." size="sm" />
                                </b-form-group>
                            </b-col>                        

                            <b-col md="3">
                                <b-form-group label="Contacto" label-for="contacto">
                                        <b-form-input readonly id="contacto" v-model="invoiceData.direccion_Contacto"
                                            placeholder="Contacto" size="sm" />
                                </b-form-group>
                            </b-col>
                            
                            <b-col md="6">
                                <b-form-group label="Calle" label-for="street">
                                    <!-- <validation-provider #default="{ errors }" name="street" rules="required"> -->
                                        <b-form-input readonly id="street" v-model="invoiceData.calle"
                                            placeholder="98 Borough bridge Road, Birmingham" class="input-sm" size="sm" />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                    <!-- </validation-provider> -->
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Núm. Exterior" label-for="num_exterior">
                                    <!-- <validation-provider #default="{ errors }" name="num_exterior" rules="required"> -->
                                        <b-form-input readonly id="num_exterior" v-model="invoiceData.num_Exterior"
                                            placeholder="Núm. exterior" size="sm" />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                    <!-- </validation-provider> -->
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Núm. Interior" label-for="num_interior">
                                    <b-form-input readonly id="num_interior" v-model="invoiceData.num_Interior"
                                        placeholder="Núm. interior" size="sm" />
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Colonia" label-for="colonia">
                                    <!-- <validation-provider #default="{ errors }" name="colonia" rules="required"> -->
                                        <b-form-input readonly id="colonia" v-model="invoiceData.colonia"
                                            placeholder="Colonia" size="sm" />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                    <!-- </validation-provider> -->
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Código Postal" label-for="codigo_postal">
                                    <!-- <validation-provider #default="{ errors }" name="codigo_postal" rules="required"> -->
                                        <b-form-input readonly id="codigo_postal" v-model="invoiceData.codigo_Postal"
                                            placeholder="Código Postal" size="sm" />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                    <!-- </validation-provider> -->
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Ciudad" label-for="ciudad">
                                    <!-- <validation-provider #default="{ errors }" name="ciudad" rules="required"> -->
                                        <b-form-input readonly id="ciudad" v-model="invoiceData.ciudad"
                                            placeholder="Ciudad" size="sm" />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                    <!-- </validation-provider> -->
                                </b-form-group>
                            </b-col>

                            <b-col md="3">
                                <b-form-group label="Estado" label-for="estado">
                                    <!-- <validation-provider #default="{ errors }" name="estado" rules="required"> -->
                                        <b-form-input readonly id="estado" v-model="invoiceData.estado"
                                            placeholder="estado" size="sm" />
                                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                    <!-- </validation-provider> -->
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </tab-content>

                <!-- articles details tab -->
                <tab-content title="Productos A Enrutar" :before-change="validationFormArticles">
                    <validation-observer ref="articlesRules" tag="form">
                        <b-row>
                            <b-col cols="12">
                                <h5 class="mb-0">
                                    Articulos a enrutar
                                </h5>
                                <small class="text-muted">Valida los articulos que se van a enrutar</small>
                            </b-col>

                            <b-col cols="12" md="12">
                                <articles-invoice :loading="loading" :isEdit="canEdtitArticles" :articles="invoiceData.articles" :isPartial="isPartial"/>
                            </b-col>
                        </b-row>
                    </validation-observer>
                </tab-content>

                <!-- Confirmation  -->
                <tab-content title="Confirmación">
                    <validation-observer ref="addressRules" tag="form">
                        <b-row>
                            <b-col cols="12" class="mb-2">
                                <h5 class="mb-0">
                                    Confirmación de datos
                                </h5>
                                <small class="text-muted"></small>
                            </b-col>

                            <!-- Card Address -->
                            <b-col md="6">
                                <b-card no-body>
                                    <b-card-body title="Dirección de Entrega">

                                        <b-row>

                                            <b-col md="12">
                                                <b-form-group label="Cliente" label-for="cliente">
                                                    <b-form-input readonly id="referencia" v-model="invoiceData.nombre"
                                                            placeholder="Cliente" size="sm" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col md="9">
                                                <b-form-group label="Referencia" label-for="referencia">
                                                    <b-form-input readonly id="referencia" v-model="invoiceData.direccion_Referencia"
                                                            placeholder="Referencia" size="sm" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col md="3">
                                                <b-form-group label="Teléfono" label-for="telefono">
                                                    <b-form-input readonly id="telefono" v-model="invoiceData.telefono1"
                                                            placeholder="Tel." size="sm" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col md="4">
                                                <b-form-group label="Contacto" label-for="contacto">
                                                    <b-form-input readonly id="contacto" v-model="invoiceData.direccion_Contacto"
                                                        placeholder="Contacto" size="sm" />
                                                </b-form-group>
                                            </b-col>
                                            
                                            <b-col md="8">
                                                <b-form-group label="Calle" label-for="street">
                                                    <b-form-input readonly id="street" v-model="invoiceData.calle"
                                                        placeholder="98 Borough bridge Road, Birmingham" class="input-sm" size="sm" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col md="3">
                                                <b-form-group label="Núm. Exterior" label-for="num_exterior">
                                                    <b-form-input readonly id="num_exterior" v-model="invoiceData.num_Exterior"
                                                        placeholder="Núm. exterior" size="sm" /> 
                                                </b-form-group>
                                            </b-col>

                                        <b-col md="3">
                                                <b-form-group label="Núm. Interior" label-for="num_interior">
                                                    <b-form-input readonly id="num_interior" v-model="invoiceData.num_Interior"
                                                        placeholder="Núm. interior" size="sm" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col md="6">
                                                <b-form-group label="Colonia" label-for="colonia">
                                                    <b-form-input readonly id="colonia" v-model="invoiceData.colonia"
                                                        placeholder="Colonia" size="sm" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col md="4">
                                                <b-form-group label="Código Postal" label-for="codigo_postal">
                                                    <b-form-input readonly id="codigo_postal" v-model="invoiceData.codigo_Postal"
                                                        placeholder="Código Postal" size="sm" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col md="4">
                                                <b-form-group label="Ciudad" label-for="ciudad">
                                                    <b-form-input readonly id="ciudad" v-model="invoiceData.ciudad"
                                                    placeholder="Ciudad" size="sm" />
                                                </b-form-group>
                                            </b-col>

                                            <b-col md="4">
                                                <b-form-group label="Estado" label-for="estado">
                                                    <b-form-input readonly id="estado" v-model="invoiceData.estado"
                                                        placeholder="estado" size="sm" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                    
                                    </b-card-body>
                                </b-card>
                            </b-col>

                            <!-- Card Articles -->
                            <b-col md="6">
                                <b-card no-body>
                                    <b-card-body>
                                        <div>
                                            <b-form-checkbox
                                                v-model="invoiceData.entrega_Inmediata"
                                                class="custom-control-primary"
                                                name="check-button"
                                                id="entrega_Inmediata"
                                                switch
                                                inline
                                            >
                                                ¿Es entrega inmediata?
                                            </b-form-checkbox>
                                        </div>                                        

                                        <b-form-group label="Comentarios" :description="`${remainingCharacters} caracteres restantes`" label-for="observaciones" label-class="font-weight-bold" class="mt-1">
                                            <b-form-textarea
                                                id="observaciones"
                                                v-model="limitedObservaciones"
                                                placeholder="Breve comentario de hasta 150 caracteres."
                                                @input="limitText"
                                                maxlength="150"
                                                />
                                        </b-form-group>

                                    </b-card-body>

                                    <b-card-body title="Articulos">
                                        <articles-invoice :loading="loading" :isEdit="false" :articles="localArticles" :isPartial="isPartial" />
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        
                        </b-row>
                    </validation-observer>
                </tab-content>
            </form-wizard>

        </div>
    </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCard,
    BCardBody,
    BListGroup, BListGroupItem, BBadge,
    BOverlay,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCardText,
    BFormTextarea,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from "@/store"
import ArticlesInvoice from '@/@core/components/invoices/ArticlesInvoice.vue';
import EventBus from "@/event-bus"
import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
import moment from 'moment'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'
const DeliveryRoutesResource = new DeliveryRoutesProvider();

export default {
    name: 'WizardPanelRouting',
    components: {
        ValidationProvider,
        ValidationObserver,
        FormWizard,
        TabContent,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        vSelect,
        BFormInvalidFeedback,
        BCard,
        BCardBody,
        BListGroup, BListGroupItem, BBadge,
        BOverlay,
        BFormCheckboxGroup,
        BFormCheckbox,
        BCardText,
        BFormTextarea,
        

        //
        ToastificationContent,
        ArticlesInvoice,
        FeatherIcon,
    },
    props: {
        active: {
            type: Boolean,
            default: () => false,
        },
        invoiceData: {
            type: Object,
            default: () => {},
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            localArticles: this.invoiceData.articles.slice(),
            canEdtitArticles: false,
            maxCharacters: 150,
            observaciones: '',
            isPartial: false,
        }
    },
    computed: {
        hasInvoiceData() {
            return Object.keys(this.invoiceData).length > 0;
        },
        limitedObservaciones: {
            get() {
                return this.observaciones;
            },
            set(value) {
                this.observaciones = value.slice(0, this.maxCharacters);
            }
        },
        remainingCharacters() {
           return this.maxCharacters - this.observaciones.length;
        }
    },
    mounted() {

    },
    methods: {
        limitText(event) {
            if (this.observaciones.length > this.maxCharacters) {
                this.observaciones = this.observaciones.slice(0, this.maxCharacters);
            }
        },
        cancelPrerouting() {
            this.$emit('resetForm')
        },
        selectAddress(evt) {
            const address = this.invoiceData.cliente.direcciones.find(item => item.dir_Cli_Id === evt)
            this.invoiceData.calle = address.calle
            this.invoiceData.direccion_Referencia = address.referencia
            this.invoiceData.telefono1 = address.telefono1
            this.invoiceData.direccion_Contacto = address.contacto
            this.invoiceData.num_Exterior = address.num_Exterior
            this.invoiceData.num_Interior = address.num_Interior  
            this.invoiceData.colonia = address.colonia
            this.invoiceData.codigo_Postal = address.codigo_Postal
            this.invoiceData.ciudad = address.ciudad
            this.invoiceData.estado = address.estado

        },
        async formSubmitted() {
            if (this.canEdtitArticles) {
                //Recorrer los articulos para determinar si es parcial siendo asi debe al menos tener un articulo cargado
                let hasArticles = this.invoiceData.articles.some(item => parseFloat(item.cargado) > 0)
                if (!hasArticles) {
                    this.warning('Debe seleccionar al menos un artículo para enrutar')
                    return false
                }
            }

            const result = await this.canRouting()
            if (result) {
                try {

                    const payload = {
                        usuario_Id: "string",
                        empresa_Id: 0,
                        sucursal_Id: store.getters['auth/getBranch'].sucursal_Id,
                        docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                        docto_Pv_Fte_Id: this.invoiceData.docto_Pv_Fte_Id ? this.invoiceData.docto_Pv_Fte_Id : this.invoiceData.docto_Pv_Id,
                        almacen_Id: this.invoiceData.almacen_Id,
                        cliente_Id: this.invoiceData.cliente_Id,
                        nombre_Cliente: this.invoiceData.nombre,
                        nombre_Almacen: this.invoiceData.almacen,
                        folio: this.invoiceData.folio,
                        importe_Neto: this.invoiceData.importe_Neto,
                        fecha_Factura: this.invoiceData.fecha,
                        fecha: moment().format('YYYY-MM-DD'),
                        tipo_Docto: this.invoiceData.tipo_Docto,
                        nombre_Consig: this.invoiceData.nombre_Consig,
                        calle: this.invoiceData.calle,
                        colonia: this.invoiceData.colonia,
                        num_Exterior: this.invoiceData.num_Exterior,
                        num_Interior: this.invoiceData.num_Interior,
                        codigo_Postal: this.invoiceData.codigo_Postal,
                        telefono1: this.invoiceData.telefono1,
                        ciudad_Id: this.invoiceData.ciudad_Id,
                        nombre_Ciudad: this.invoiceData.ciudad,
                        estado_Id: this.invoiceData.estado_Id,
                        nombre_Estado: this.invoiceData.estado,
                        direccion_Rerefencia: this.invoiceData.direccion_Referencia,
                        direccion_Contacto: this.invoiceData.direccion_Contacto,
                        estatus_Factura: this.invoiceData.estatus,
                        nombre_Vendedor: this.invoiceData.vendedor,
                        tipo_Cliente: this.invoiceData.tipo,
                        plazo: this.invoiceData.plazo,
                        dir_Cli_Id: this.invoiceData.dir_Cli_Id,
                        estatus: "ABIERTA",
                        fecha_Alta: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                        fecha_Actualizacion: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                        rutasEntregasDetalles: this.invoiceData.articles.map(item => ({
                            rutas_Entregas_Id: 0,
                            docto_Pv_Det_Id: item.docto_Pv_Det_Id,
                            docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                            articulo_Id: item.articulo_Id,
                            articulo_Nombre: item.articulo,
                            unidad_Medida: item.um,
                            unidad_Medida_Total: item.um_Total,
                            entregado: item.entregado,
                            devoluciones: item.devoluciones,
                            pendiente: item.pendiente,
                            cargado: this.isPartial ? item.pendiente : item.cargado,
                            estatus_Retorno: item.estatus_Retorno,
                            fecha_Alta: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            fecha_Actualizacion: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                            centralRutasEntregasDTO: null,
                        })),
                        observaciones: this.observaciones,
                        entrega_Inmediata: this.invoiceData.entrega_Inmediata,
                    }

                    this.loading = true
                    const { data } = await DeliveryRoutesResource.store(payload)
                    this.loading = false
                    if (data.isSuccesful) {
                        EventBus.$emit('successRouting', data)
                        this.success(data.message)
                    } else {
                        if (data.data && data.data.isReload) {
                            this.$swal.fire({
                                title: `Error al generar la salida`,
                                text: data.message,
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonText: 'Recargar',
                                cancelButtonText: 'Cancelar',
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                    cancelButton: "btn btn-outline-danger ml-1",
                                },
                                showClass: {
                                    popup: "animate__animated animate__flipInX",
                                },
                                buttonsStyling: false,
                            }).then( async (result) => {
                                this.$emit('resetForm')
                            })
                        } else {
                            this.danger(data.message)
                        }
                    }
                }catch(e) {
                    this.loading = false
                    this.handleResponseErrors(e)
                }
            }
        },
        async canRouting() {
            const details = []
            this.invoiceData.articles.forEach(article => {                       
                details.push({
                    articulo_Id: article.articulo_Id,
                    articulo_Nombre: article.articulo,
                    devoluciones: article.devoluciones,
                    docto_Pv_Det_Id: article.docto_Pv_Det_Id ? article.docto_Pv_Det_Id : this.invoiceData.docto_Pv_Id,
                    docto_Pv_Id: this.invoiceData.docto_Pv_Id,
                    enrutado: true,
                    entregado: article.entregado,
                    estatus_Retorno: article.estatus_Retorno,
                    fecha_Actualizacion: moment(),
                    fecha_Alta: moment(),
                    id: 0,
                    pendiente: article.pendiente,
                    cargado: article.cargado,
                    rutas_Entregas_Id: 0, //this.invoiceData.delivery_rounting.id,
                    unidad_Medida: article.um,
                    unidad_Medida_Total: article.um_Total,
                    unidades: article.cargado > 0 ? ( article.cargado * -1 ): article.pendiente,
                    chofer_Id: 1,
                    camion_Id: 1,
                    nombre_Camion: ' -- ',
                    nombre_Chofer: ' -- ',
                    isReturn: false,
                })
            });

            this.loading = true
            const { data } = await DeliveryRoutesResource.canRouting(this.invoiceData.docto_Pv_Id, this.invoiceData.tipo_Docto, details)
            this.loading = false

            if (data.isSuccesful) {                
                if (data.data) {
                    return true
                } else {
                    this.danger(data.message)
                    this.$emit('resetForm')
                    return false
                }
            } else {
                this.loading = false
                this.danger(data.message)
                this.$emit('resetForm')
                return false
            }
        },
        validationForm() {

            //Resteo de datos a cargar
            this.invoiceData.articles.forEach(element => {                                    
                element.cargado = 0
                return element
            });
            this.isPartial = false

            return new Promise((resolve, reject) => {
                this.$refs.accountRules.validate().then(success => {
                    if (success) {  
                        this.$nextTick(async () => {
                            const result = await this.canRouting()
                            if (result) {
                                this.$swal.fire({
                                    title: `¿Poner en Ruta los Productos?`,
                                    text: 'Selecciona una opción para determinar qué productos se van a poner en ruta.',
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Total',
                                    cancelButtonText: 'Parcial',
                                    customClass: {
                                        confirmButton: "btn btn-primary",
                                        cancelButton: "btn btn-outline-danger ml-1",
                                    },
                                    showClass: {
                                        popup: "animate__animated animate__flipInX",
                                    },
                                    buttonsStyling: false,
                                    allowOutsideClick: false
                                }).then( (result) => {                            
                                    if (result.isConfirmed) { //Total
                                        this.canEdtitArticles = false
                                        this.isPartial = false
                                    } else if (result.isDismissed) { //Parcial
                                        this.isPartial = true
                                        this.canEdtitArticles = true
                                    }
                                    resolve(true)
                                })
                            } else {
                                reject()
                            }
                        })
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFormArticles() {
            return new Promise((resolve, reject) => {
                this.$refs.articlesRules.validate().then(success => {
                    if (success) {
                        
                        this.$nextTick(async () => {
                            const result = await this.canRouting()
                            if (result) {                                
                                if (this.canEdtitArticles) {
                                    //Recorrer los articulos para determinar si es parcial siendo asi debe al menos tener un articulo cargado
                                    const hasArticles = this.invoiceData.articles.some(item => parseFloat(item.cargado) > 0)
                                    if (!hasArticles) {
                                        this.warning('Debe seleccionar al menos un artículo para enrutar')
                                        reject()
                                    } else {
                                        resolve(true)
                                    }
                                } else {
                                    resolve(true)
                                }
                            } else {
                                reject()
                            }
                        })
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFormAddress() {
            return new Promise((resolve, reject) => {
                this.$refs.addressRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
        validationFormSocial() {
            return new Promise((resolve, reject) => {
                this.$refs.socialRules.validate().then(success => {
                    if (success) {
                        resolve(true)
                    } else {
                        reject()
                    }
                })
            })
        },
    },
    watch: {
        active(value) {
            
        },
        invoiceData(value) {
            
        }
    }
}
</script>