<template>
    <div>
        <b-row v-if="!isOnPreRoute">
            <b-col cols="12" xl="8" md="8">
                <b-card no-body>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="12">
                                <search-invoices @selected="selectInvoice" @resetForm="resetForm" :showArticles="true"
                                    :showDetail="true" @onPreRoute="onPreRoute" @onSearch="onSearchQuery" :doctoPvId="doctoPvId"
                                     />
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col cols="12" md="4" xl="4" class="invoice-actions">
                <b-row>
                    <b-col cols="12">                        
                        <info-routing-invoice
                            :info="invoice" :is-all="invoice.delivery_rounting || isSearch ? false : true"
                            @selectRouting="selectRouting" :showDetail="isShowDetail"/>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!-- Show wizard form on preroute -->
        <b-row v-if="isOnPreRoute">
            <b-col cols="12">
                <routing-invoice :active="isOnPreRoute" :invoiceData="invoice" @resetForm="resetForm"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import BCardCode from "@core/components/b-card-code";
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
    BCardText,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BAvatar,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    VBToggle,
    BOverlay,
    BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import EventBus from "@/event-bus"
import SearchInvoices from '@/@core/components/invoices/SearchInvoices.vue'
import CardInfoCustomer from '@/@core/components/customers/CardInfoCustomer.vue';
import RoutingInvoice from '@/@core/components/invoices/RoutingInvoice.vue';
import InfoRoutingInvoice from '@/@core/components/invoices/InfoRoutingInvoice.vue';

export default {
    name: 'ComponenteRouterPanel',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BCardFooter,
        BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot,
        BCardText,
        BButton,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormCheckbox,
        BFormTextarea,
        BAvatar,
        vSelect,
        flatPickr,
        ValidationProvider,
        BFormInvalidFeedback,
        ValidationObserver,
        BOverlay,
        BSpinner,
        BCardCode,

        //
        SearchInvoices,
        CardInfoCustomer,
        RoutingInvoice,
        InfoRoutingInvoice,

    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            loading: false,
            activeSearchCustomer: false,
            resetSearch: false,
            invoice: {},
            isOnPreRoute: false,
            isSearch: false,
            doctoPvId: null,
            isShowDetail: false,
        }
    },
    computed: {
        branch() {
            return store.getters['auth/getBranch']
        },
        isControlTotal() {
            return store.getters['auth/getConexion']?.control_Total || false;
        },
        hasInvoiceData() {
            return Object.keys(this.invoice).length > 0;
        }
    },
    created() {

        //Se dispara cuando un el enrutamiento se ha realizado con éxito
        EventBus.$on('successRouting', async (e) => {
            console.log('successRouting desde routing panel', e)
            if(e.isSuccesful) {                
                EventBus.$emit('refreshRoutings', true)
                this.resetForm()
            }
        });
    },
    async mounted() {
        router.currentRoute.meta.pageTitle = "Enrutamiento de Pedidos"
    },
    methods: {
        selectRouting(evt) {
            this.isShowDetail = true
            EventBus.$emit('selectedDocto', evt)
        },
        onSearchQuery(evt) {
            this.isSearch = evt ? true : false
        },
        routeInvoice() {
            this.$swal.fire({
                title: `Enrutar Factura ${this.invoice.folio}`,
                text: '¿Está seguro de querer clasificar este documento como pre-ruta?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar',
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                showClass: {
                    popup: "animate__animated animate__flipInX",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    //this.$emit('onPreRoute')
                }
            })
        },
        selectInvoice(e) {
            this.invoice = { ...e }

            console.log('selectInvoice', e)
        },
        resetForm() {
            this.invoice = {}
            this.isShowDetail = false
            this.isOnPreRoute = false
            this.isSearch = false
        },
        onPreRoute() {
            this.isOnPreRoute = true
        }
    },
    watch: {

    }
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/vue/libs/vue-flatpicker.scss";

.card-footer {
    padding: 0.75rem 0.75rem;
    background-color: rgba(34, 41, 47, 0.03);
    border-top: 1px solid rgba(34, 41, 47, 0.125);
}
</style>